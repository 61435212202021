<template>
	<div>
		<cui-login />
	</div>
</template>
<script>
import CuiLogin from '@/components/system/Auth/Login'
export default {
	components: {
		CuiLogin,
	},
}
</script>
