<template>
	<div>
		<div class="text-center" style="padding: 10px 0;">
			<img src="resources/images/Logo-Origami.png" width="80%" />
		</div>
		<a-spin :tip="loadingLabel" size="large" :spinning="loading">
			<div class="card" :class="$style.container">
				<div class="text-dark font-size-24 mb-3">
					<strong>{{ $t('system.auth.login.header') }}</strong>
				</div>
				<a-form class="mb-4" :form="form" @submit="handleSubmit">
					<a-form-item label="Email">
						<a-input size="large" placeholder="Email" v-decorator="['username', { rules: [{ required: true, message: $t('system.auth.login.form.username.required') }] }]" />
					</a-form-item>
					<a-form-item label="Contraseña">
						<a-input size="large" placeholder="Password" type="password" v-decorator="['password', { rules: [{ required: true, message: $t('system.auth.login.form.password.required') }] }]" />
					</a-form-item>
					<a-button type="primary" htmlType="submit" size="large" class="text-center w-100" :loading="loading">
						<strong>{{ $t('system.auth.login.login') }}</strong>
					</a-button>
				</a-form>
				<router-link to="/auth/forgot-password" class="kit__utils__link font-size-16">{{ $t('system.auth.login.forgotPassword') }}</router-link>
			</div>
		</a-spin>
	</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'CuiLogin',
	computed: {
		...mapState(['settings']),
		...mapGetters('auth', ['loading', 'loadingLabel']),
	},
	data: function () {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					this.$store.dispatch('auth/LOGIN', { ...values })
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import '@/components/system/Auth/style.module.scss';
</style>